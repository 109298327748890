/* Общие стили для страницы */
body, html {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
    /*color: black;*/
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
}

/* Стили для приложения */
#app {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
}

/* Стили для карты */
#map {
    flex-grow: 1;
    width: 100%;
    height: 100%;
    position: relative;
}

/* Стили для сайдбара */
#sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 22%;
    height: 100%;
    background-color: #f8f9fa;
    overflow-y: auto;
    padding: 20px;
    border-right: 1px solid #dee2e6;
    transform: translateX(0); /* По умолчанию видим */
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
}

#sidebar.sidebar-hide {
    transform: translateX(-100%);
}

#sidebar.sidebar-show {
    transform: translateX(0);
}

/* Стили для фиксированного заголовка сайдбара */
.sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

/* Стили для кнопки закрытия */
.btn-close {
    border: none;
    background: transparent;
    font-size: 1.8rem; /* Увеличенный размер иконки (1.2rem * 1.5) */
    cursor: pointer;
    color: #000; /* Цвет иконки */
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Стили для кнопки "Главное меню" */
.btn-main-menu {
    border: none;
    background: transparent;
    font-size: 1.8rem; /* Увеличенный размер иконки (1.2rem * 1.5) */
    cursor: pointer;
    color: #000; /* Цвет иконки */
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Стили для кнопки показа сайдбара */
#toggle-sidebar {
    display: none; /* Скрыта по умолчанию */
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 1001; /* Повышенный z-index для кнопки поверх сайдбара */
    background-color: #cce5ff;
    color: #007bff;
    border: none;
    padding: 15px; /* Увеличено на 1.5 раза */
    font-size: 36px; /* Увеличено на 1.5 раза (24px * 1.5) */
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    border-radius: 5px;
}

#toggle-sidebar.toggle-visible {
    display: flex;
    align-items: center;
    justify-content: center;
}

#toggle-sidebar.toggle-hidden {
    display: none;
}

/* Админка-тоггл */
#admin-toggle-btn {
    display: none; /* Скрыта по умолчанию */
    position: fixed;
    top: 70px; /* Под кнопкой toggle-sidebar */
    left: 10px;
    z-index: 1001;
    background-color: #cce5ff;
    color: #007bff;
    border: none;
    padding: 15px;
    font-size: 36px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    border-radius: 5px;
}

#admin-toggle-btn.toggle-visible {
    display: flex;
    align-items: center;
    justify-content: center;
}

#admin-toggle-btn.toggle-hidden {
    display: none;
}

/* Стили для кнопки профиля */
#profile-btn {
    display: none; /* Скрыта по умолчанию */
    position: fixed;
    top: 130px; /* Под кнопкой admin-toggle-btn */
    left: 10px;
    z-index: 1001; /* Повышенный z-index для кнопки поверх других элементов */
    background-color: #cce5ff;
    color: #007bff;
    border: none;
    padding: 15px;
    font-size: 36px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    border-radius: 5px;
}

#profile-btn.toggle-visible {
    display: flex;
    align-items: center;
    justify-content: center;
}

#profile-btn.toggle-hidden {
    display: none;
}

/* Убираем дополнительные кнопки закрытия внутри динамического контента */
#sidebar-content .btn-close {
    display: none;
}

/* Убираем изменение цвета и эффекты при наведении */
.btn-close:hover,
.btn-main-menu:hover {
    color: #aaa; /* Более светлый цвет при наведении */
    background-color: transparent; /* Не заливается цветом */
    transform: none; /* Убираем любые трансформации */
    transition: color 0.3s;
}

/* Стили для попапа */
.popup-content img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
}

.popup-content .card {
    max-width: 100%;
}

.popup-content .card-img-top {
    width: 100%;
    height: auto;
    max-height: 150px;
    object-fit: cover;
}

.popup-content .card-body {
    padding: 10px;
}

.popup-content .card-text {
    font-size: 14px;
}

/* Стили для кнопок */
button {
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 15px 22.5px; /* Увеличено на 1.5 раза */
    border-radius: 5px;
    transition: background-color 0.3s;
    font-size: 1.5rem; /* Увеличено на 1.5 раза (1rem * 1.5) */
}

button:hover {
    background-color: #66b2ff; /* Светлый цвет при наведении */
}

/* Стили для главного меню */
.main-menu {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.main-menu button {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center; /* Центрируем текст */
}

.main-menu button i {
    position: absolute;
    left: 10px; /* Приплюсовано к левому краю */
}

/* Стили для формы редактирования */
.edit-form-header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.edit-form-header i {
    cursor: pointer;
    font-size: 1.5rem; /* Унифицированный размер иконки */
}

/* Унификация размеров заголовков */
#sidebar-content h2 {
    font-size: 1.5rem; /* Унифицированный размер заголовков */
    margin-bottom: 20px;
}


/* Стили для категорий меню */

.categories-menu {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.categories-menu .category-btn {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Выравнивание иконки слева */
    gap: 10px; /* Отступ между иконкой и текстом */
    padding: 10px; /* Добавление внутреннего отступа */
}

.categories-menu .category-btn i {
    font-size: 1.5rem; /* Размер иконки */
}

.category-logo {
    max-height: 20px;
}

.style-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    border: 1px solid #ccc;
    margin: 4px 0;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.style-item.selected {
    font-weight: bold;
    background-color: #e0f7fa; /* Цвет выделенного стиля */
}

.select-button {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.select-button:hover {
    background-color: #0056b3; /* Цвет при наведении */
}


/* Дополнительные стили для кнопки "Назад" */
#back-to-categories {
    display: flex;
    align-items: center;
    gap: 10px;
}

/* Стили для категорий в подкатегориях */
.categories-menu .category-btn:hover {
    background-color: #e2e6ea; /* Светлый фон при наведении */
    transition: background-color 0.3s;
}

/* Стили для подкатегорий меню */
.subcategories-menu {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.subcategories-menu .subcategory-btn {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center; /* Центрируем текст */
    gap: 10px; /* Добавляем отступ между иконкой и текстом */
}

.subcategories-menu .subcategory-btn i {
    font-size: 1.5rem; /* Размер иконки */
}

/* Modal styles */
/* В вашем файле main.css */
.modal {
    display: none; /* Скрыто по умолчанию */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Полупрозрачный фон */
    z-index: 1050; /* Высокий z-index для поверх всех элементов */
    overflow: hidden;
}

.modal.show {
    display: block;
}


.modal-content {
    background-color: #fefefe;
    margin: 10% auto;
    padding: 20px;
    width: 400px;
    position: relative;
    /* TODO test it */
    font-size: 18px; /* Увеличиваем размер шрифта */
}

.close-button {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

.gif-marker {
    cursor: pointer;
    pointer-events: auto;
}

.gif-marker img {
    width: 25px;
    height: 25px;
}

/* Адаптивные стили */
@media (max-width: 768px) {
    #sidebar {
        width: 100%;
        height: auto;
        bottom: 0;
        top: auto;
        transform: translateY(0); /* По умолчанию видим */
    }

    #sidebar.sidebar-hide {
        transform: translateY(100%);
    }

    #sidebar.sidebar-show {
        transform: translateY(0);
    }

    #map {
        height: calc(100vh - 200px);
    }

    #toggle-sidebar {
        top: auto;
        bottom: 10px;
    }
}

#working-hours-section {
    transition: max-height 0.3s ease;
    overflow: hidden;
}


.btn-custom-blue {
    background-color: #add8e6; /* Светло-синий */
    color: #000; /* Черный текст */
    border: none;
    transition: background-color 0.3s, color 0.3s;
}

.btn-custom-blue:hover {
    background-color: #00008b; /* Темно-синий */
    color: #fff; /* Белый текст */
}
