body, html {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: PT Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

#app {
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
}

#map {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  position: relative;
}

#sidebar {
  z-index: 1000;
  background-color: #f8f9fa;
  border-right: 1px solid #dee2e6;
  width: 22%;
  height: 100%;
  padding: 20px;
  transition: transform .3s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  transform: translateX(0);
}

#sidebar.sidebar-hide {
  transform: translateX(-100%);
}

#sidebar.sidebar-show {
  transform: translateX(0);
}

.sidebar-header {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  display: flex;
}

.btn-close, .btn-main-menu {
  cursor: pointer;
  color: #000;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  display: flex;
}

#toggle-sidebar {
  z-index: 1001;
  color: #007bff;
  cursor: pointer;
  background-color: #cce5ff;
  border: none;
  border-radius: 5px;
  padding: 15px;
  font-size: 36px;
  transition: background-color .3s, color .3s;
  display: none;
  position: fixed;
  top: 10px;
  left: 10px;
}

#toggle-sidebar.toggle-visible {
  justify-content: center;
  align-items: center;
  display: flex;
}

#toggle-sidebar.toggle-hidden {
  display: none;
}

#admin-toggle-btn {
  z-index: 1001;
  color: #007bff;
  cursor: pointer;
  background-color: #cce5ff;
  border: none;
  border-radius: 5px;
  padding: 15px;
  font-size: 36px;
  transition: background-color .3s, color .3s;
  display: none;
  position: fixed;
  top: 70px;
  left: 10px;
}

#admin-toggle-btn.toggle-visible {
  justify-content: center;
  align-items: center;
  display: flex;
}

#admin-toggle-btn.toggle-hidden {
  display: none;
}

#profile-btn {
  z-index: 1001;
  color: #007bff;
  cursor: pointer;
  background-color: #cce5ff;
  border: none;
  border-radius: 5px;
  padding: 15px;
  font-size: 36px;
  transition: background-color .3s, color .3s;
  display: none;
  position: fixed;
  top: 130px;
  left: 10px;
}

#profile-btn.toggle-visible {
  justify-content: center;
  align-items: center;
  display: flex;
}

#profile-btn.toggle-hidden, #sidebar-content .btn-close {
  display: none;
}

.btn-close:hover, .btn-main-menu:hover {
  color: #aaa;
  background-color: #0000;
  transition: color .3s;
  transform: none;
}

.popup-content img {
  border-radius: 5px;
  max-width: 100%;
  height: auto;
}

.popup-content .card {
  max-width: 100%;
}

.popup-content .card-img-top {
  object-fit: cover;
  width: 100%;
  height: auto;
  max-height: 150px;
}

.popup-content .card-body {
  padding: 10px;
}

.popup-content .card-text {
  font-size: 14px;
}

button {
  cursor: pointer;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  padding: 15px 22.5px;
  font-size: 1.5rem;
  transition: background-color .3s;
}

button:hover {
  background-color: #66b2ff;
}

.main-menu {
  flex-direction: column;
  gap: 15px;
  display: flex;
}

.main-menu button {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.main-menu button i {
  position: absolute;
  left: 10px;
}

.edit-form-header {
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  display: flex;
}

.edit-form-header i {
  cursor: pointer;
  font-size: 1.5rem;
}

#sidebar-content h2 {
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.categories-menu {
  flex-direction: column;
  gap: 15px;
  display: flex;
}

.categories-menu .category-btn {
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 10px;
  display: flex;
}

.categories-menu .category-btn i {
  font-size: 1.5rem;
}

.category-logo {
  max-height: 20px;
}

.style-item {
  border: 1px solid #ccc;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  margin: 4px 0;
  padding: 8px;
  transition: background-color .3s;
  display: flex;
}

.style-item.selected {
  background-color: #e0f7fa;
  font-weight: bold;
}

.select-button {
  color: #fff;
  cursor: pointer;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  transition: background-color .3s;
}

.select-button:hover {
  background-color: #0056b3;
}

#back-to-categories {
  align-items: center;
  gap: 10px;
  display: flex;
}

.categories-menu .category-btn:hover {
  background-color: #e2e6ea;
  transition: background-color .3s;
}

.subcategories-menu {
  flex-direction: column;
  gap: 15px;
  display: flex;
}

.subcategories-menu .subcategory-btn {
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
  position: relative;
}

.subcategories-menu .subcategory-btn i {
  font-size: 1.5rem;
}

.modal {
  z-index: 1050;
  background: #00000080;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.modal.show {
  display: block;
}

.modal-content {
  background-color: #fefefe;
  width: 400px;
  margin: 10% auto;
  padding: 20px;
  font-size: 18px;
  position: relative;
}

.close-button {
  color: #aaa;
  float: right;
  cursor: pointer;
  font-size: 28px;
  font-weight: bold;
}

.gif-marker {
  cursor: pointer;
  pointer-events: auto;
}

.gif-marker img {
  width: 25px;
  height: 25px;
}

@media (width <= 768px) {
  #sidebar {
    width: 100%;
    height: auto;
    top: auto;
    bottom: 0;
    transform: translateY(0);
  }

  #sidebar.sidebar-hide {
    transform: translateY(100%);
  }

  #sidebar.sidebar-show {
    transform: translateY(0);
  }

  #map {
    height: calc(100vh - 200px);
  }

  #toggle-sidebar {
    top: auto;
    bottom: 10px;
  }
}

#working-hours-section {
  transition: max-height .3s;
  overflow: hidden;
}

.btn-custom-blue {
  color: #000;
  background-color: #add8e6;
  border: none;
  transition: background-color .3s, color .3s;
}

.btn-custom-blue:hover {
  color: #fff;
  background-color: #00008b;
}
/*# sourceMappingURL=index.908c594b.css.map */
